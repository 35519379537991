import { useGetCalculationProgress, useGetOngoingCalculations, useStartCalculationProcess } from '@/lib/queries/calculation-api/calculation';
import { CalculationProgress, CalculationStartResult } from '@/lib/types/calculation';
import { Alert, Button, Card, Flex, Progress, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Rekenregels: React.FunctionComponent = () => {
  const COMPLETED_STATUS = 'completed';

  const { t } = useTranslation(['core', 'errors']);

  const [ongoing, setOngoingId] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<CalculationProgress | undefined>();

  const { data: ongoingCalculationId, isLoading: isLoadingOngoing, isError: isOngoingErorr } = useGetOngoingCalculations();
  const { data: progressData, isError: isProgressError } = useGetCalculationProgress(ongoing);
  const startCalculations = useStartCalculationProcess();

  // It may take a few seconds for the system to start the calculation process which is why the progress
  // endpoint may return a 404 the first time calling it.
  const taskIsPending = (): boolean => ongoing !== undefined && isProgressError;

  const hasError = (): boolean => (isOngoingErorr || isProgressError) && !taskIsPending();

  useEffect(() => {
    setIsLoading(isLoadingOngoing);
  }, [isLoadingOngoing]);

  useEffect(() => {
    setOngoingId(ongoingCalculationId?.id);
    setIsLoading(false);
  }, [ongoingCalculationId]);

  useEffect(() => {
    setProgress(progressData);
    setIsLoading(false);
  }, [progressData]);

  const berekenen = () => {
    setOngoingId(undefined);
    setProgress(undefined);
    setIsLoading(true);

    startCalculations.mutate(undefined, {
      onSuccess: (res: CalculationStartResult) => {
        setOngoingId(res.id);
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Card title={t('rekenregels.title')} loading={isLoading}>
        <Space direction="vertical" size="small" style={{ display: 'flex' }}>
          {progress?.status && t(progress.status, { keyPrefix: 'rekenregels.statusses' })}
          {hasError() && <Alert message={t('rekenregels.somethingWentWrong', { ns: 'errors' })} type="error" showIcon />}
          <Progress percent={progress?.progress} status={hasError() ? 'exception' : progress?.status == COMPLETED_STATUS ? 'success' : 'active'} />
          {ongoing && progress?.status != COMPLETED_STATUS && <Alert message={t('rekenregels.info')} type="info" showIcon />}
          <Flex style={{ paddingTop: 15 }} justify="end">
            <Button disabled={ongoing !== undefined && progress?.status != COMPLETED_STATUS} onClick={berekenen}>
              {t('rekenregels.herberekenenRapportages')}
            </Button>
          </Flex>
        </Space>
      </Card>
    </>
  );
};
