import { createFilterParams, fetchAuthenticated } from '@/lib/utilities/api';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DeelnemerInput, DeelnemerOutput } from '@/lib/types/deelnemer';
import { PagedResponse, PaginationQueryParameters } from '@/shared/types/general';

const QUERY_KEY = ['deelnemer'];
const BASE_URL = `${import.meta.env.VITE_CONFIG_API_URL}/deelnemers`;

export const useGetDeelnemers = (params?: PaginationQueryParameters<DeelnemerOutput>): UseQueryResult<PagedResponse<DeelnemerOutput>, Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => fetchAuthenticated<PagedResponse<DeelnemerOutput>>(`${BASE_URL}?${createFilterParams(params ?? {})}`),
  });
};

export const useCreateDeelnemer = () => {
  return useMutation<DeelnemerOutput, Error, DeelnemerInput>({
    mutationFn: (newDeelnemer: DeelnemerInput) =>
      fetchAuthenticated<DeelnemerOutput>(`${BASE_URL}`, {
        json: newDeelnemer,
        method: 'POST',
      }),
    mutationKey: [...QUERY_KEY],
  });
};

export const useGetDeelnemerById = (id: number): UseQueryResult<DeelnemerOutput, Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () => fetchAuthenticated<DeelnemerOutput>(`${BASE_URL}/${id}`),
    enabled: !!id,
  });
}

export const useUpdateDeelnemer = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation<DeelnemerInput, Error, DeelnemerInput>({
    mutationFn: (newDeelnemer: DeelnemerInput) =>
      fetchAuthenticated<DeelnemerInput>(`${BASE_URL}/${id}`, {
        json: newDeelnemer,
        method: 'PUT',
      }),
    mutationKey: [...QUERY_KEY, id],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...QUERY_KEY, id] })
    }
  })
}

export const useGetParentDeelnemers = (): UseQueryResult<DeelnemerOutput[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY],
    queryFn: () => fetchAuthenticated<DeelnemerOutput[]>(`${BASE_URL}/hoofdvestigingen`),
  });
};