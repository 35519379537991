import { WebGebruikerInput, WebGebruikerOutput } from "@/lib/types";
import { CalculationProgress, CalculationStartResult } from "@/lib/types/calculation";
import { fetchAuthenticated, createFilterParams } from "@/lib/utilities";
import { PaginationQueryParameters, PagedResponse } from "@/shared/types/general";
import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const QUERY_KEY = ['calculation'];
const BASE_URL = `${import.meta.env.VITE_CALCULATION_API_URL}`;

export const useGetOngoingCalculations = (): UseQueryResult<CalculationStartResult, Error> =>
    useQuery({
        queryKey: [QUERY_KEY, 'ongoing'],
        queryFn: () => fetchAuthenticated<CalculationStartResult>(`${BASE_URL}/ongoing`),
    });

export const useGetCalculationProgress = (id: string | undefined): UseQueryResult<CalculationProgress, Error> =>
    useQuery({
        queryKey: ['progress'],
        queryFn: () => fetchAuthenticated<CalculationProgress>(`${BASE_URL}/progress/${id}`),
        refetchInterval: res => res.state.data?.progress == 100 ? false : 1500,
        enabled: !!id,
    });

export const useStartCalculationProcess = () => {
  const queryClient = useQueryClient();

  return useMutation<CalculationStartResult, Error>({
    mutationFn: () =>
      fetchAuthenticated<CalculationStartResult>(`${BASE_URL}/calculate`, {
        method: 'POST',
      }),
    mutationKey: [...QUERY_KEY, 'calculate'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...QUERY_KEY, 'calculate'] });
    },
  });
};