import { ReportRequestLog } from "@/lib/types/reportrequestlog";
import { createFilterParams, fetchAuthenticated } from "@/lib/utilities";
import { PagedResponse, PaginationQueryParameters } from "@/shared/types/general";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

export const useGetReportRequestLog = (params?: PaginationQueryParameters<ReportRequestLog>): UseQueryResult<PagedResponse<ReportRequestLog>, Error> => {
  const QUERY_KEY = ['reportrequestlog'];
  const BASE_URL = `${import.meta.env.VITE_REPORT_API_URL}/rapportages/requestlog`;

  return useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => {
      return fetchAuthenticated<PagedResponse<ReportRequestLog>>(`${BASE_URL}?${createFilterParams(params ?? {})}`)
    }
  });
};