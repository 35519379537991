
export interface ReportRequestLog {
  date?: number;
  type?: ReportRequestType;
  email?: string;
  uOnummer?: string;
  role?: string;
}

export enum ReportRequestType {
  Milieu = "Milieurapportage",
  AlleBedrijven = "Alle bedrijven",
  Aggregatie = "Aggregatie",
  AggregatieGewas = "Aggregatie gewas"
}