import { BasicTableColumn } from '@/lib/types';
import { BasicTable } from '@/lib/components/tables/BasicTable';
import { Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ReportRequestLog, ReportRequestType } from '@/lib/types/reportrequestlog';
import { useGetReportRequestLog } from '@/lib/queries/config-api/reportrequestlog';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export const ReportRequestOverview: React.FunctionComponent = () => {
  const { t } = useTranslation('core');

  const columns: BasicTableColumn<ReportRequestLog>[] = [
    {
      label: 'reportrequestlog.date', dataIndex: 'date', filterable: true, sortable: true, width: 150, render: (value: unknown) => {
        const dateStr = value as string;
        return dateStr ? dayjs(dateStr).format('DD-MM-YYYY HH:mm') : '';
      }
    },
    {
      label: 'reportrequestlog.type', dataIndex: 'type', filterable: false, sortable: true, width: 150, render: (value: unknown) => {
        const values = Object.values(ReportRequestType);
        return values[value as number] ?? `Onbekend`;
      },
    },
    { label: 'reportrequestlog.email', dataIndex: 'email', filterable: true, sortable: true, width: 150 },
    { label: 'reportrequestlog.uonummer', dataIndex: 'uOnummer', filterable: true, sortable: true, width: 150 },
    { label: 'reportrequestlog.role', dataIndex: 'role', filterable: true, sortable: true, width: 150 },
  ];

  return (
    <Card
      title={t('reportrequestlog.title')}
      bordered={true}
    >
      <BasicTable<ReportRequestLog> loadEntries={useGetReportRequestLog} columns={columns} />
    </Card>
  );
};