import Logo from '@/assets/logo.jpg';
import { Layout, Menu, MenuProps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './core.module.scss';
import { Roles, useUserContext } from '@/lib/context/userContext';
import { useFeature } from '@/lib/hooks/useFeature';

type MenuItem = Required<MenuProps>['items'][number];
const { Sider } = Layout;

interface SiderProps {
  selectedKey: string;
  onSelect: (key: string) => void;
}

function getItem(label: string, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: string): MenuItem {
  return {
    label,
    key,
    icon: icon ? icon : null,
    children,
    type,
  } as MenuItem;
}

export const SideNav: React.FunctionComponent<SiderProps> = ({ selectedKey, onSelect }) => {
  const { t } = useTranslation('core');
  const { roles } = useUserContext();
  const [menuItems, setMenuItems] = useState<any[]>([]);

  const showRekenregels = useFeature('show-calculation')

  const hasAtLeastOneRole = (rolesToCheck: Roles[]): boolean => {
    return roles.some(role => rolesToCheck.includes(role));
  };

  useEffect(() => {
    setMenuItems([
      {
        label: t('menu.home'),
        path: '/',
        visible: true,
      },
      {
        label: t('menu.bevoegdGezag'),
        path: '/bevoegdgezag',
        visible: true,
      },
      {
        label: t('menu.webGebruiker'),
        path: '/webgebruiker',
        visible: true,
      },
      {
        label: t('menu.deelnemer'),
        path: '/deelnemer',
        visible: hasAtLeastOneRole([Roles.Administrator, Roles.Bevoegdgezag]),
      },
      {
        label: t('menu.milieudienst'),
        path: '/milieudienst',
        visible: true,
      },
      {
        label: t('menu.cluster'),
        path: '/cluster',
        visible: true,
      },
      {
        label: t('menu.rekenregels'),
        path: '/rekenregels',
        visible: showRekenregels && hasAtLeastOneRole([Roles.Administrator]),
      },
      {
        label: t('menu.reportrequestoverview'),
        path: '/report-request-log',
        visible: hasAtLeastOneRole([Roles.Administrator]),
      },
    ]);
  }, [roles]);

  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sider className={styles.sideNav} collapsed={collapsed} width={250} onCollapse={value => setCollapsed(value)} collapsible breakpoint="lg">
      <div className={styles.logo}>
        <img src={Logo}></img>
      </div>

      <Menu activeKey={selectedKey} defaultSelectedKeys={[selectedKey]} onSelect={e => onSelect(e.key)} mode="inline" items={menuItems.filter(x => x.visible).map(x => getItem(x.label, x.path))} />
    </Sider>
  );
};