import ky, { HTTPError, Options } from 'ky';
import { msalInstance, tokenRequestBase } from './msal';
import { PaginationQueryParameters, ValidationError } from '@/shared/types/general';

const DEFAULT_TIMEOUT = 1000 * 30;

export const createFilterParams = <T>(parameters: PaginationQueryParameters<T>): URLSearchParams | undefined => {
  if (!parameters) {
    return;
  }

  const params = new URLSearchParams();

  if (!!parameters.page) params.set("page", parameters.page.toString());
  if (!!parameters.pageSize) params.set("pageSize", parameters.pageSize.toString());
  if (!!parameters.sortField) params.set("sortField", parameters.sortField.toString());
  if (!!parameters.sortOrder) params.set("sortOrder", parameters.sortOrder.toString());

  for (const key of Object.keys(parameters.filterValues ?? {})) {
    const value = parameters.filterValues![key]
    for (let k in value) {
      params.append(key, value[k]);
    }
  }

  return params;
};

export const fetchAuthenticated = async <T>(url: string, config: Options = {}, timeout: number = DEFAULT_TIMEOUT): Promise<T> => {
  const account = msalInstance.getAllAccounts()[0];
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const msalResponse = await msalInstance.acquireTokenSilent({
    ...tokenRequestBase,
    account: account,
  });

  const accessToken = msalResponse.accessToken;
  const headers = (config.headers as Headers) || new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  if (!headers.has('Content-Type')) {
    headers.append('Content-Type', 'application/json');
  }

  config.headers = headers;

  // This seems to be nessecary to handle http errors with useMutation as Ky just throws them but not as promise
  try {
    return ky(url, { ...config, timeout, retry: 0 }).json<T>();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const httpErrorConverter = async (error: unknown): Promise<string | ValidationError[]> => {
  if (error instanceof HTTPError) {
    const errorJson = await error.response.json();
    if (errorJson.status === 400) {
      const errors = Object.values(errorJson.errors);
      return errors.join('\n');
    } else if (errorJson.message) {
      return errorJson.message;
    } else if (Array.isArray(errorJson)) {
      return errorJson as ValidationError[];
    }
  } else if (error instanceof Error) {
    return error.message;
  }
  return 'Unexpected Error';
};