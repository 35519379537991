import { useAccount, useMsal } from '@azure/msal-react';
import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';

interface Context {
  username?: string;
  roles: Roles[];
}

export enum Roles {
  Administrator,
  Bevoegdgezag,
  Dienstverlener,
  Deelnemer,
  Toezichthouder,
}

const UserContext = createContext<Context>({
  roles: [],
});

export const useUserContext = (): Context => React.useContext(UserContext);

export function tokenContainsRole(roles: Roles[]): boolean {
  const { roles: tokenRoles } = useUserContext();
  return tokenRoles.some(role => roles.includes(role));
}

export const UserProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [roles, setRoles] = useState<Roles[]>([]);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const mapRoleToEnum = (role: string): Roles | undefined => {
    const roleMapping: { [key: string]: Roles } = {
      administrator: Roles.Administrator,
      bevoegdgezag: Roles.Bevoegdgezag,
      dienstverlener: Roles.Dienstverlener,
      deelnemer: Roles.Deelnemer,
      toezichthouder: Roles.Toezichthouder,
    };

    return roleMapping[role.toLowerCase()];
  };

  useEffect(() => {
    if (account) {
      const roleString = account.idTokenClaims?.extension_Roles as string;
      const roles = roleString ? roleString.split(',').map(mapRoleToEnum) : [];
      setRoles(roles as Roles[]);
    } else {
      setRoles([]);
    }

    return () => setRoles([]);
  }, [account]);

  return (
    <UserContext.Provider
      value={{
        username: account?.username,
        roles: roles,
      }}>
      {children}
    </UserContext.Provider>
  );
};
