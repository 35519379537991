import React, { PropsWithChildren, createContext } from 'react';
import { FeatureFlags } from '../types/features';

export const FeatureFlagContext = createContext<FeatureFlags>({});

export const useFeatureFlagContext = (): FeatureFlags => React.useContext(FeatureFlagContext);

export const FeatureFlagProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  // Currently we use hard-coded features - as we do not have a feature manager yet.
  // Using this hard-coded features it does give the oppertunity to keep one main branch.
  const data: FeatureFlags = {
    'show-calculation': import.meta.env.VITE_F_CALCULATION === 'true',
  };

  return <FeatureFlagContext.Provider value={data}>{children}</FeatureFlagContext.Provider>;
};