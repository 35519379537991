import { DeelnemerForm } from '@/lib/components/forms/DeelnemerForm';
import { useGetBevoegdGezagenSummary } from '@/lib/queries';
import { useGetDeelnemerById, useGetParentDeelnemers, useUpdateDeelnemer } from '@/lib/queries/config-api/deelnemer';
import { ToelichtingsTypeEnum } from '@/lib/types';

import { App, Button, Card, List, Spin, Divider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const { Text } = Typography;

export const DeelnemerEdit: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const id = Number(useParams().id?.toString());
  const updateDeelnemer = useUpdateDeelnemer(id);
  const { message: messageApi } = App.useApp();
  const { t } = useTranslation('core');

  const { data: deelnemerResponse, isLoading: isDeelnemerLoading } = useGetDeelnemerById(id);
  const { data: bevoegdGezagResponse, isLoading: isBevoegdGezagLoading } = useGetBevoegdGezagenSummary();
  const { data: parentResponse, isLoading: isParentLoading } = useGetParentDeelnemers();

  const formId = 'editDeelnemerForm';

  const ChangeLog: React.FunctionComponent = () => {
    return (
      <div style={{ maxWidth: '50%', margin: '0 auto' }}>
        <Divider>{t('deelnemer.toelichtingWijzigingenTitel')}</Divider>
        <br />
        <List
          itemLayout="horizontal"
          loading={isDeelnemerLoading}
          dataSource={deelnemerResponse?.toelichtingen ?? []}
          locale={{ emptyText: t('deelnemer.toelichtingenLeeg') }}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={<Text style={{ textTransform: 'initial' }}>({ToelichtingsTypeEnum[item.type]}) {item.toelichting}</Text>}
                description={`${item.aangemaaktDoorGebruikerEmail} - ${new Date(item.aangemaaktOp).toLocaleDateString()}`}
              />
            </List.Item>
          )}
        />
      </div>
    );
  };

  return (
    <>
      {isDeelnemerLoading || isBevoegdGezagLoading || isParentLoading ? (
        <Spin size="large" />
      ) : (
        <Card
          title={t('deelnemer.update')}
          bordered={true}
          extra={
            <Button type="primary" htmlType="submit" form={formId}>
              Opslaan
            </Button>
          }>
          <DeelnemerForm
            id={formId}
            onFinish={values => {
              updateDeelnemer.mutate(
                {
                  ...values,
                  vestigingsNummer: values.vestigingsNummer.toString(),
                  huisNummer: values.huisNummer.toString(),
                  postadresPostcodeCijfers: values.postadresPostcodeCijfers.toString(),
                  vestigingsPostcodeCijfers: values.vestigingsPostcodeCijfers?.toString(),
                  nrGemeente: values.nrGemeente?.toString(),
                  nrWaterschap: values.nrWaterschap?.toString(),
                },
                {
                  onSuccess: () => {
                    messageApi.success(t('deelnemer.editSuccess'));
                    navigate('/deelnemer');
                  },
                }
              );
            }}
            initialValues={deelnemerResponse ?? []}
            bevoegdGezagen={bevoegdGezagResponse ?? []}
            hoofdvestigingen={parentResponse ?? []}
          />
          <ChangeLog />
        </Card>
      )}
    </>
  );
};
